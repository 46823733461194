<template>
  <!--
    The view for the Peak Login Redirect
  -->
  <div class="peakLoginRedirectView">
    <LoadingPlaceholder v-if="loading" />
  </div>
</template>

<script>
export default {
  name: "PeakLoginRedirectView",
  props: {
    installationId: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      userIp: null
    }
  },
  async created () {
    await this.addMyIp();
    await this.addUserIp();
    this.login();
  },
  methods: {
    async addUserIp () {
      const body = {
        installationId: this.installationId,
        type: 'dynamic',
        ipAddress: this.userIp,
        active: true,
        expiry: 1
      }
      
      await this.axios
        .post(`/Installation/InstallationIp?user=true&skipError=true`, body)
        .then(async (res) => {
          if (res) {
            this.$snotify.success(this.$t("ipAddedSuccessfully"));
          }
        });
    },
    async addMyIp () {
      await fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        this.userIp = data.ip
      })
      .catch(error => {
        this.$snotify.error(
         'Error while getting IP. Try deactivating AdBlocker.'
        );
        this.error_clear();
        this.error_validate(error);
      });
    },
    login () {
      this.loading = true;
      this.axios.post('/SoftwareComponent/LoginToShopBackend', { installationId: this.installationId, username: this.username})
      .then((response) => {
        window.location.replace(response.data);
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>